exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-contact-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/GenieSlack/GenieSlack/web-server/src/content/contact.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-contact-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-how-to-use-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/GenieSlack/GenieSlack/web-server/src/content/how-to-use.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-how-to-use-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-member-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/GenieSlack/GenieSlack/web-server/src/content/member.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-member-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-privacy-policy-eng-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/GenieSlack/GenieSlack/web-server/src/content/privacy-policy-eng.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-privacy-policy-eng-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-privacy-policy-jp-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/GenieSlack/GenieSlack/web-server/src/content/privacy-policy-jp.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-privacy-policy-jp-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-privacy-policy-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/GenieSlack/GenieSlack/web-server/src/content/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-privacy-policy-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-terms-of-service-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/GenieSlack/GenieSlack/web-server/src/content/terms-of-service.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-terms-of-service-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-what-is-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/GenieSlack/GenieSlack/web-server/src/content/what-is.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-what-is-mdx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

